import React from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO/SEO"

import Layout from "../components/layout"
import Container from "../components/container/container"
import Feedback from "../components/feedback/feedback"
import SeeAlso from "../components/see-also/see-also";

import IntroMin from "../images/Intro-min.png"
import input_of_rpc_parameters from "../images/input-of-rpc-parameters.png"
import testing_1 from "../images/testing-1.png"

const year = new Date().getFullYear()

const IndexPage = () => (
  <Layout>
    <SEO
      title="Cloud services for managing, monitoring and testing devices (CWMP/TR-069, USP/TR-369)"
      desc="Cloud services for managing, monitoring and testing devices (CWMP/TR-069, USP/TR-369)"
      canonical="https://tr069.cloud"
      pathname=""
    />
    <section className="intro">
      <Container>
        <h1 className="intro__title">
          Cloud services for managing, monitoring and testing devices (CWMP/TR-069, USP/TR-369)
        </h1>
        <p className="intro__text">
          Our reliable, scalable and affordable cloud solutions that meet your specific needs of remote device management and monitoring.
        </p>
      </Container>
    </section>
    <section className="what-is-it">
      <Container className="see-also">
        <SeeAlso
          img="dms"
          title="Cloud ACS (CWMP/TR-069, USP/TR-369)"
          description="Cloud service for device management (“Cloud DMS”) is a TR-069 ACS and TR-369 Controller for remote provisioning, management and monitoring all kind of devices that support CWMP/USP protocols."
          link="https://acs.tr069.cloud/"
          buttonTitle="Manage your devices">
        </SeeAlso>
        <SeeAlso
          img="wifi"
          title="Wi-Fi Optimization"
          description="Wi-Fi Radio Resource Management (Wi-Fi RRM) service is one of the solutions that allows you to analyse radio spectrum for group of managed APs (Wi-Fi routers) and to select them the best Wi-Fi channels."
          link="https://wifi.tr069.cloud/"
          buttonTitle="Get more information">
        </SeeAlso>
        <div className="class10">
            <div className="class1">
                <div className={`bg bg-test-portal`}/>
                <div className="class2" style={{"marginTop":"0","borderRadius":"0 0 4px 4px"}}>
                    <h2 className="class3">
                        Cloud platform for device interoperability testing  (CWMP/TR-069, USP/TR-369)
                    </h2>
                    <p className="class4">
                       Improve the quality and speed of testing your devices for compliance with the Broadband Forum standards. <a
                            className="header-promo__link--gray"
                            href="#read-more"
                            title="Cloud platform for device interoperability testing  (CWMP/TR-069, USP/TR-369)"
                            >Read more</a>
                    </p>
                    <a
                        className="link"
                        href="https://interop.tr069.cloud/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Cloud platform for device interoperability testing  (CWMP/TR-069, USP/TR-369)"
                        >
                        Start testing
                    </a>
                </div>
            </div>
        </div>
      </Container>
      <div className="anchor" id="read-more">
        <Container className="what-is-it__box">
        <img
          className="what-is-it__img"
          src={IntroMin}
          alt="Cloud device testing"
          width="350"
          height="212"
          title="Cloud device testing"
        />
        <div className="what-is-it__content">
          <h2 className="what-is-it__title">
            What is Interoperability Testing Portal?
          </h2>
          <p className="what-is-it__text">
            The cloud Interop Platform allows you to test devices to assure
            consistency and adherence to the protocols:
          </p>
          <ul className="what-is-it__list">
            <li>
              <a
                href="https://www.broadband-forum.org/download/TR-069_Amendment-6.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                TR-069 or CPE WAN Management Protocol (CWMP)
              </a>
            </li>
            <li>
              <a
                href="https://www.broadband-forum.org/download/TR-369.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                TR-369 or User Services Platform (USP)
              </a>
            </li>
          </ul>
          <p className="what-is-it__text">
            The service includes the set of tests developed by the Broadband
            Forum for certification program:
          </p>
          <ul className="what-is-it__list">
            <li>
              <a
                href="https://www.broadband-forum.org/technical/download/ATP-069_Issue-2_Corrigendum-1.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                ATP-069 Issue 2 Corrigendum 1: TR-069 Abstract Test Plan
              </a>
            </li>
            <li>
              <a
                href="https://www.broadband-forum.org/technical/download/ATP-069_Issue-2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                ATP-069 Issue 2: TR-069 Abstract Test Plan
              </a>
            </li>
            <li>
              <a
                href="https://www.broadband-forum.org/technical/download/ATP-069.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                ATP-069: TR-069 Abstract Test Plan
              </a>
            </li>
            <li>
              <a
                href="https://www.broadband-forum.org/technical/download/TR-469.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                TR-469: Conformance Test Plan for User Services Platform Agents
              </a>
            </li>
            <li>
              <a>USP Plugfest Test Plan</a>
            </li>
          </ul>
        </div>
      </Container>
      </div>
    </section>
    <section className="features">
      <Container>
        <h2 className="features__title">Features</h2>
        <ul className="features__list">
          <li className="features__element">
            <h3 className="features__subtitle">Testing made simple</h3>
            <p className="features__text">
              The Interop testing service is provided for testing DUTs (Device
              Under Test) in various conditions and with application of
              different types of scenarios. The service allows you to monitor
              your tests, devices and results subject to their compliance with
              required specifications and also to identify problems with
              implementation of the protocol.
            </p>
          </li>
          <li className="features__element">
            <h3 className="features__subtitle">Achieve desired results</h3>
            <p className="features__text">
              In accordance with obtained test results, you can create the
              environment necessary for achievement of desired indicators. In
              order to guarantee the permanent execution of monitoring and
              further analysis, we have developed this service, which makes it
              possible to carry out your tests 24 hours a day and seven days a
              week from any place in the world directly in your browser.
            </p>
          </li>
          <li className="features__element">
            <h3 className="features__subtitle">Tests that work for you</h3>
            <p className="features__text">
              The Interop testing service contains both standard test tasks
              proposed by BBF and specific tests developed by our company
              proceeding from the experience of using real scenarios applicable
              by the telecom operators, including various service models,
              diagnostic tests, security issues (Vulnerability) and other user
              scenarios.
            </p>
          </li>
          <li className="features__element">
            <h3 className="features__subtitle">Create unique reports</h3>
            <p className="features__text">
              According to the test results, the service generates reports that
              you can share with engineers or other representatives of the
              client.
            </p>
          </li>
          <li className="features__element">
            <h3 className="features__subtitle">
              Run test on any platform you want
            </h3>
            <p className="features__text">
              Interoperability testing portal was designed to run tests on
              multiple platforms including PCs, laptops, tablets, phones etc.
            </p>
          </li>
        </ul>
        <a
          className="features__link"
          href="https://interop.tr069.cloud"
          target="_blank"
          rel="noopener noreferrer"
          title="Interoperability testing portal"
        >
          Try it now
        </a>
      </Container>
    </section>
    <section className="explanation">
      <Container>
        <h2 className="visually-hidden">How does the testing tool work?</h2>
        <div className="explanation__block">
          <p className="explanation__text">
            Our custom web-based testing tool allows you to check equipment
            troubleshooting, to detect malfunctions of your devices and to
            validate the interoperability of your CPE with cloud device
            management platform. You may execute any RPC methods, because our
            platform is based on powerful TR-069 ACS and USP Controller. The
            online testing platform will help you to prepare your devices for
            BBF certification, and also to check conformity of your CPE with
            TR-069 (TR-69, CWMP), USP (TR-369) protocols.
          </p>
          <img
            className="explanation__img"
            src={input_of_rpc_parameters}
            alt="Equipment troubleshooting"
          />
        </div>
        <div className="explanation__block explanation__block--reverse">
          <p className="explanation__text">
            This service includes not only tests recommended by the{" "}
            <a
              className="explanation__bbf-link"
              href="https://www.broadband-forum.org"
              target="_blank"
              rel="noopener noreferrer"
              title="Broadband forum"
            >
              Broadband Forum
            </a>
            , but also tests which are based primarily on our own experience in
            implementation of TR-069 among different service providers (Tier 3
            to Tier 1) over the last 15 years. We don't provide certification,
            the service only allows you to perform tests and validate the
            compliance of your devices.
          </p>
          <img
            className="explanation__img"
            src={testing_1}
            alt="Implementation of TR-069"
          />
        </div>
        <p className="explanation__end">
          Additionally, we can develop the special set of tests by your
          requirements and provide ongoing consulting and technical support to
          our Clients.
        </p>
        <a
          className="explanation__link"
          href="https://interop.tr069.cloud"
          target="_blank"
          rel="noopener noreferrer"
          title="Interoperability testing portal"
        >
          Test your device
        </a>
      </Container>
    </section>
    <section className="articles">
      <Container>
        <h2 className="fixed-anchor articles__title" id="articles">
          Articles
        </h2>
        <div className="articles__box">
          <div className="articles__item-box">
            <article className="articles__item">
              <div className="articles__item-bg"></div>
              <h3 className="articles__item-title">
                Clarification of certain terms and explanation of information
                security aspects
              </h3>
              <Link
                className="articles__item-link"
                to="/articles/clarification-of-certain-terms-and-explanation-of-information-security-aspects"
              >
                Read
              </Link>
            </article>
          </div>
          <div className="articles__item-box">
            <article className="articles__item">
              <div className="articles__item-bg"></div>
              <h3 className="articles__item-title">
                Successive evolution of TR-069 - the User Services Platform
                (TR-369)
              </h3>
              <Link
                className="articles__item-link"
                to="/articles/the-user-services-platform-tr-369"
              >
                Read
              </Link>
            </article>
          </div>
          <div className="articles__item-box">
            <article className="articles__item">
              <div className="articles__item-bg"></div>
              <h3 className="articles__item-title">
                Specification of Technical Report 069
              </h3>
              <Link
                className="articles__item-link"
                to="/articles/specification-of-technical-report-069"
              >
                Read
              </Link>
            </article>
          </div>
          <div className="articles__item-box">
            <article className="articles__item">
              <div className="articles__item-bg"></div>
              <h3 className="articles__item-title">The Technical Report 069</h3>
              <Link
                className="articles__item-link"
                to="/articles/the-technical-report-069"
              >
                Read
              </Link>
            </article>
          </div>
        </div>
      </Container>
    </section>
    {/* <QA/> */}
    <Feedback />
    <section className="about">
      <Container>
        <h2 className="visually-hidden">Our merits</h2>
        <ul className="about__list">
          <li className="about__element">
            <span className="about__mark">{year - 2004}</span> years of
            experience in supporting highly loaded services
          </li>
          <li className="about__element">
            <span className="about__mark">100+</span> million users worldwide
          </li>
          <li className="about__element">
            <span className="about__mark">{year - 2014}</span> years of cloud
            infrastructure development expertise
          </li>
        </ul>
      </Container>
    </section>
  </Layout>
)

export default IndexPage
