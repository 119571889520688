import React, { useState } from "react"

import Container from "../container/container"

import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'

import "./feedback.module.scss"

const Feedback = () => {

    const [isCaptchaVaild, setIsCaptchaVaild] = useState(false)

    const [state, setState] = useState({
        user: '',
        email: '',
        message: ''
    })

    const [answer, setAnswer] = useState('');

    const captchaSuccessChange = () => {
        setIsCaptchaVaild(true)
    }

    const onExpired = () => {
        setIsCaptchaVaild(false)
    }

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    const sendFeedback = (event) => {
        event.preventDefault();
        
        axios.post('https://interop.tr069.cloud/api/v1.0/user/send_mail', state)
            .then(response => {
                setState({})
                setAnswer('Thank You, we have successfully received your message!')
                console.log(response.data)
            })
            .catch(error => {
                setState({})
                setAnswer('Something went wrong :( Refresh the page and try again.')
                console.log(answer)
            })
    }   

    return (
        <section className="feedback">
            <Container>
                <h2 className="feedback__title">
                    Contact us
                </h2>
                <p className="feedback__subtitle">
                    If you have any questions just fill out the form below and we will respond to you as soon as possible
                </p>
                <form className={ answer === '' ? 'feedback__form' : 'feedback__form feedback__form--hidden'} method="POST" action="https://interop.tr069.cloud/api/v1.0/user/send_mail" onSubmit={sendFeedback}>
                    <div className="feedback__form-control">
                        <input required className="feedback__input" type="text" name="user" id="feedback-name" placeholder="Your Name" onChange={event => handleChange(event)}/>
                        <label className="feedback__label" htmlFor="feedback-name">Name</label>
                    </div>
                    <div className="feedback__form-control">
                        <input required autoComplete="on" className="feedback__input" type="email" name="email" id="feedback-email" placeholder="Your Email" onChange={event => handleChange(event)}/>
                        <label className="feedback__label" htmlFor="feedback-email">Email</label>
                    </div>
                    <div className="feedback__form-control">
                        <textarea required className="feedback__input feedback__input--textarea" rows="6" name="message" id="feedback-msg" placeholder="Write Your Message" onChange={event => handleChange(event)}></textarea>
                        <label className="feedback__label" htmlFor="feedback-msg">Message</label>    
                    </div>
                    <ReCAPTCHA
                        onExpired={onExpired}
                        sitekey="6LdjvLMUAAAAAFtLBTpszbY10euKPrV0A16H_znz"
                        hl="en"
                        className="feedback__form-control"
                        onChange={captchaSuccessChange}
                    />
                    <button className="feedback__btn" type="submit" disabled={!isCaptchaVaild}>Send message</button>
                </form> 
                <div className={answer === '' ? 'feedback__answer' : 'feedback__answer feedback__answer--show'}>{answer}</div>
            </Container>
        </section>
    )
}

export default Feedback