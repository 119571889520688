import React from "react"
import "./see-also.module.scss"


const SeeAlso = ({children, img, title, description, buttonTitle, link}) => (
    <div className="class10">
        <div className="class1">
            <div className={`bg bg-${img}`}></div>
            <div className="class2" style={{"marginTop":"0","borderRadius":"0 0 4px 4px"}}>
                <h2 className="class3">
                    {title}
                </h2>
                <p className="class4">
                    {description}
                </p>
                <a
                    className="link"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={title}
                    >
                    {buttonTitle}
                </a>
            </div>
        </div>
    </div>
)

export default SeeAlso
